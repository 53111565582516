const HOST = 'https://dreamlandia.net'
export const API_SEND_URL = HOST+'/api/send/'

export const API_REGISTER_URL = HOST+'/api/users/register/'
export const API_USERCHECK_URL = HOST+'/api/users/check/username/'
export const API_EMAILCHECK_URL = HOST+'/api/users/check/email/'

export const API_REMIND_URL = HOST+'/api/users/remind/'
export const API_AUTH_URL = HOST+'/api/users/auth/'

export const API_USER_URL = HOST+'/api/users/profile/'
export const API_USEREDIT_URL = HOST+'/api/users/edit/'
export const API_USERVISIT_URL = HOST+'/api/users/visit/'
export const API_AVATAR_URL = HOST+'/api/users/edit/avatar/'

export const API_DREAMS_URL = HOST+'/api/dreams/'
export const API_IMAGE_URL = HOST+'/api/dreams/edit/image/'
export const API_DREAMSHOME_URL = HOST+'/api/dreams/home/'
export const API_DREAMSALL_URL = HOST+'/api/dreams/all/'
export const API_DREAMSREADY_URL = HOST+'/api/dreams/ready/'
export const API_DREAMSFALSE_URL = HOST+'/api/dreams/false/'
export const API_DREAMEDIT_URL = HOST+'/api/dreams/edit/'
export const API_DREAMVISIBILITYEDIT_URL = HOST+'/api/dreams/edit/visibility/'
export const API_DREAMSTATUSEDIT_URL = HOST+'/api/dreams/edit/status/'
export const API_DREAMAPPEND_URL = HOST+'/api/dreams/append/'
export const API_DREAMDETAIL_URL = HOST+'/api/dreams/detail/'

export const API_LEVELS_URL = HOST+'/api/dreams/levels/'

export const MEDIA_URL = HOST+''

export const API_USERS_ALL_URL = HOST+'/api/users/all/'
export const API_DREAMS_ALL_URL = HOST+'/api/dreams/all/'


// Анимации https://blog.avada.io/css/image-effects
// https://stackforgeeks.com/blog/djangorest-framework-react-js-axios-post-json-data-which-contains-image-file

