import React, { PureComponent } from 'react'
import { Navigate, Link } from 'react-router-dom'
import makeCarousel from 'react-reveal/makeCarousel'
import Slide from 'react-reveal/Slide'
import { API_DREAMSHOME_URL, MEDIA_URL } from './props'
import Fade from 'react-reveal/Fade'
import moment from 'moment'
import './css/hover.css'
var months = ["Января", "Февраля", "Марта", "Апреля", "Мая", "Июня","Июля", "Августа", "Сентября", "Октября", "Ноября", "Декабря"]
moment.updateLocale('ru', {months: months})
moment.locale('ru')

export default class Home extends PureComponent {
  constructor(props){
    super(props)
    this.state = {dreamList: '', dreams: 0, currentCount: 16, flexClass: 'home' }
  }


componentDidMount() {
  if(this.state.dreams == 0){
    fetch(API_DREAMSHOME_URL).then((response) => response.json()).then((data) => this.setState(() => ({ dreamList: data.sort((a, b) => b.created > a.created ? 1 : -1), dreams: data.length })))
    this.props.sun()
  }
}

flexClick = () => {
  //  this.setState({flexClass: 'focus'})
}
  render(){
    const {dreamList, flexClass} = this.state
    const dreams = this.state.dreams
    let fulfilledDate = ""
    let createdDate = ""
    let nowDate = ""
    let deltaDate = ""
    let deltaDays = ""
    let remainDays = ""
    let deltaPast = ""
    let pastDays = ""
    let remainDelta = ""
    let remainPercent = ""

    const CarouselUI = ({ children }) => <div>{children}</div>;
    const Carousel = makeCarousel(CarouselUI);

    return (
      <section id="dreams" class="dreams-home">
      <h1 className="sticky-top">Создай реальность</h1>
      <Fade left cascade fraction="1">   
          <div className={flexClass} onClick={this.flexClick} id="flex-wrap">
                  {!dreams && <aside className="empty-dreams"><i class="bi bi-balloon"></i><br /><span>Пока нет</span></aside>}
                  <Carousel defaultWait={3000}>
                  {Object.keys(dreamList).map((item) => 
                   <Slide right>
                   <article className={flexClass} key={dreamList[item].id}>
                      <aside className="btn-box">
                        <i className="bi bi-cloud-haze2"></i>
                        <i className={dreamList[item].visibility ? 'bi bi-broadcast-pin green' : 'bi bi-broadcast-pin gray'}></i>
                      </aside> 
                      <figure className="photo home">
                        <div class="glow-wrap"><i class="glow"></i></div>
                        <figcaption>{dreamList[item].title}<div className="row"><div className="col-6"><span>{moment(dreamList[item].created).format('DD MMMM YYYY')}</span><b>создано</b></div><div className="col-6"><span>{moment(dreamList[item].fulfilled).format('DD MMMM YYYY')}</span><b>исполнится</b></div></div></figcaption>
                        <img src={dreamList[item].image ? `${MEDIA_URL+''+dreamList[item].image}` : 'img/noimg.png'} />
                        <div className="props">
                          {fulfilledDate = new Date(dreamList[item].fulfilled).getTime()}
                          {createdDate = new Date(dreamList[item].created).getTime()}
                          {nowDate = new Date().getTime()}
                          {deltaDate = fulfilledDate - createdDate}
                          {deltaDays = Math.ceil(deltaDate / (1000 * 60 * 60 * 24))}
                          {deltaPast = nowDate - createdDate}
                          {pastDays = Math.ceil(deltaPast / (1000 * 60 * 60 * 24))}
                          {remainDays = Math.ceil((deltaDate - deltaPast) / (1000 * 60 * 60 * 24))}
                          {remainDelta = Math.ceil((pastDays * 100) / deltaDays)}
                          {remainDelta > 100 ? remainDelta = 100 : null}
                          {remainPercent = 100 - remainDelta}
                          {dreamList[item].status ? remainDelta = 100 : null}
                        </div>
                        {remainDelta === 100 ? <meter value="100" class={dreamList[item].status ? 'dream-true' : 'dream-false'}></meter> : <meter value={remainDelta} data-remain={remainDays} min="0" max="100" low="20" high="70" optimum="100"></meter>}
                        <p className="dream-rubric">
                          {(dreamList[item].rubric == 1) && 'дом / недвижимость'}
                          {(dreamList[item].rubric == 2) && 'транспорт / автомобили'}
                          {(dreamList[item].rubric == 3) && 'путешествие / отдых'}
                          {(dreamList[item].rubric == 4) && 'здоровье / красота'}
                          {(dreamList[item].rubric == 5) && 'деньги / финансы'}
                          {(dreamList[item].rubric == 6) && 'любовь / отношения'}
                          {(dreamList[item].rubric == 7) && 'образование / саморазвитие'}
                          {(dreamList[item].rubric == 8) && 'карьера / бизнес / работа'}
                          {(dreamList[item].rubric == 9) && 'искусство / культура'}
                          {(dreamList[item].rubric == 10) && 'досуг / хобби'}
                          {(dreamList[item].rubric == 11) && 'спорт'}
                          {(dreamList[item].rubric == 12) && 'друзья'}
                          {(dreamList[item].rubric == 13) && 'животные'}
                          {(dreamList[item].rubric == 14) && 'другое'}
                        </p>
                      </figure>  
                  </article>
                  </Slide>
                  )}
                  </Carousel>
          </div>
      </Fade>
      </section>
    )
}
}

