import React from 'react'
import ReactDOM from 'react-dom/client'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import './css/index.css'
import registerWorker from './registerWorker'

import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
      <App />
)

registerWorker()